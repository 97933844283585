
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function About() {
  return (
    <div className="App">
      <header className="App-header">
        <h4 className='sideText mobileToggle'>Concrete. Re-Imagened.</h4>
      </header>
    </div>
  );
}

export default About;
