import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import imageFactory from './imageFactory';
import {LinkContainer} from 'react-router-bootstrap';
import {Link} from "react-router-dom";

const imgData = [
  {
    img:'/1.JPG',
    title:'Img 1',
    url:'https://www.instagram.com/p/CjrS4lCLXMf/'
  },
  {
    img:'/2.JPG',
    title:'Img 2',
    url:'https://www.instagram.com/p/ChLPsjXv-U0/'
  },
  {
    img:'/3.JPG',
    title:'Img 3',
    url:'https://www.instagram.com/p/CfaBrdjOT8z/'
  },
  {
    img:'/4.JPG',
    title:'Img 4',
    url:'https://www.instagram.com/p/CaK8JCPv35w/'
  },
  {
    img:'/5.JPG',
    title:'Img 5',
    url:'https://www.instagram.com/p/CbyVLNTOX7J/'
  },
  {
    img:'/6.JPG',
    title:'Img 6',
    url:'https://www.instagram.com/p/CRcRRSHMFdU/'
  },
  {
    img:'/7.JPG',
    title:'Img 7',
    url:'https://www.instagram.com/p/CXXHERrvB7z/'
  },
  {
    img:'/8.JPG',
    title:'Img 8',
    url:'https://www.instagram.com/p/CPuHkQ5Mry6/'
  },
];

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Container fluid className="masterPadding">
          <div className='padBar'/>
            {imageFactory(imgData.slice(0,4))}
          <Row className="contactBar">
            <Col xs={1} md={3}>
              <h4 className='sideText mobileToggle'>Designed in California.</h4>
            </Col>
            <Col xs={10} md={6}>
              <h1 className='titleText'>Cassius Castings</h1>
            </Col>
            <Col xs={1} md={3}>
                <h4 className='sideText mobileToggle'>Concrete. Re-Imagined.</h4>
            </Col>
          </Row>
          {imageFactory(imgData.slice(4,8))}
          <div className='padBar'/>
        </Container>
      </header>
    </div>
  );
}

export default App;
