import './App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function imageFactory(imgList) {
    return(
        <Row className="imgBar">
            {imgList.map((imgItem) =>
                <Col xs={6} md={3} className="gridPadding">
                    <div>
                        <a href={imgItem.url}>
                        <img  className='imgBoxWidth' src={process.env.PUBLIC_URL + imgItem.img} alt={imgItem.img}/>
                        </a>
                    </div>
                </Col>
            )}
        </Row>
    )
}

export default imageFactory;